import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useCallback,
} from 'react';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Selection,
    RemoteOperations,
    Grouping,
    GroupPanel,
    StateStoring,
    Sorting,
    HeaderFilter,
    LoadPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { fetchReports } from '@src/utils/apiServices/reportsRequests';
import {
    getDataGridFilters,
    sortDataBySeveralFields,
    onSelectionChangedHandler,
    onCellPreparedHandler,
    getDisabledKeysFromReports,
    getSelectedChainsLastReportsIds,
    nonSelectableReportTypes,
} from '@src/utils/helpers/devExpressHelpers';
import { dxShortDateFormat, getShortDate } from '@src/utils/helpers/dateUtils';
import InfoText from '@src/components/InfoText';
import { Col, Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import {
    ORDER_SERVICE_PROPERTY_SEARCH_ORDER_FORM,
    ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS,
} from '@src/utils/constants/routerConstants';
import { FilterOutlined, SelectOutlined } from '@ant-design/icons';

const removedFromNewIds = [];
const allowedPageSizes = [10, 25, 50, 100];

const isStatusNewVisible = (newRptFlag, id, isWebDelivery) => (
    newRptFlag && isWebDelivery && !removedFromNewIds.includes(id)
);

const renderReportType = (args, removeFromNew, shouldRemoveFromNew, dataGrid, isWebDelivery) => {
    const {
        text,
        rowIndex,
        data: {
            url,
            newRptFlag,
            id,
            state,
        },
    } = args;
    const isStateVisible = !!state;

    return (
        url && url.trim()
            ? (
                <a // eslint-disable-line react/jsx-no-target-blank
                    href={url}
                    onClick={() => {
                        if (isStatusNewVisible(newRptFlag, id, isWebDelivery) && shouldRemoveFromNew) {
                            removeFromNew(id);
                            removedFromNewIds.push(id);
                            dataGrid.repaintRows(rowIndex);
                        }
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {text}
                    {isStateVisible ? ` - ${state}` : ''}
                </a>
            )
            : text + (isStateVisible ? ` - ${state}` : '')
    );
};

const renderReportStatus = ({ text, data: { newRptFlag, id } }, combinedReports, isWebDelivery) => {
    let status = '';
    if (text === 'C') {
        status = <span className="label label-success">Completed</span>;
        if (isStatusNewVisible(newRptFlag, id, isWebDelivery) && !combinedReports.includes(id)) {
            status = (
                <>
                    {status}
                    <span className="label label-primary">New</span>
                </>
            );
        }
    } else if (text === 'X') {
        status = <span className="label label-danger">Canceled</span>;
    } else if (text === 'S') {
        status = <span className="label label-primary">Submitted</span>;
    } else if (text === 'P') {
        status = <span className="label label-default">Pending</span>;
    } else if (text === 'I') {
        status = <span className="label label-warning">Incomplete</span>;
    }

    return status;
};

// eslint-disable-next-line react/jsx-no-target-blank
const renderOrderNumber = ({ text }) => <a href={`${ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS}/${text}`} target="_blank">{text}</a>;

const renderDataCellWithActions = ({ text }, cellContent, dataField, pushAdditionalValueForFilter) => (
    <div className="dx-data-cell-with-actions">
        {cellContent}
        <span className="dx-cell-actions-wrapper">
            <Button
                onClick={() => pushAdditionalValueForFilter({ name: dataField, value: text })}
                icon={<FilterOutlined />}
                size="small"
            />
        </span>
    </div>
);

const renderTaxActions = (rowData, filters) => {
    const { data: { id } } = rowData;

    return (
        <div className="buttons-block-wrapper">
            <Link
                to={`${ORDER_SERVICE_PROPERTY_SEARCH_ORDER_FORM}/${id}?filters=${JSON.stringify(filters)}`}
            >
                <Button
                    icon={<SelectOutlined />}
                    size="small"
                    title="Select"
                >
                    Select
                </Button>
            </Link>
        </div>
    );
};

const renderJudgmentActions = (rowData, selectOrder) => {
    const { data: { id, chain } } = rowData;
    return (
        <div className="buttons-block-wrapper">
            <Button.Group>
                <Button
                    icon={<SelectOutlined />}
                    size="small"
                    title="Select"
                    onClick={() => selectOrder(chain?.[0] || id)}
                >
                    Select
                </Button>
            </Button.Group>
        </div>
    );
};

const getColumns = (
    columnNames,
    removeFromNew,
    shouldRemoveFromNew,
    dataGridRef,
    combinedReports,
    pushAdditionalValueForFilter,
    selectOrder,
    defaultSorting,
    filters,
    isWebDelivery,
    isSctUser,
) => {
    const columns = {
        'id': <Column
            key="id"
            dataField="id"
            cssClass="cell-report-number"
            caption="Report #"
            dataType="string"
            allowHeaderFiltering={false}
            allowGrouping={false}
            visible={isSctUser}
        />,
        'orderno': <Column
            key="orderno"
            dataField="orderno"
            cssClass="cell-order-number"
            caption="Order #"
            dataType="string"
            allowHeaderFiltering={false}
            allowGrouping={false}
        />,
        'reqdate': <Column
            key="reqdate"
            dataField="reqdate"
            cssClass="cell-order-date"
            caption="Order Date"
            dataType="date"
            hidingPriority={3}
            format={dxShortDateFormat}
            calculateCellValue={rowData => getShortDate(rowData.reqdate)}
        />,
        'refno': <Column
            key="refno"
            dataField="refno"
            cssClass="cell-reference-number"
            caption="Reference #"
            allowHeaderFiltering={false}
            cellRender={rowData => {
                const { text, column: { dataField } } = rowData;
                return (
                    renderDataCellWithActions(rowData, text, dataField, pushAdditionalValueForFilter)
                );
            }}
        />,
        'webno': <Column
            key="webno"
            dataField="webno"
            cssClass="cell-order-number"
            caption="Order #"
            dataType="string"
            cellRender={rowData => (
                renderDataCellWithActions(rowData, renderOrderNumber(rowData), 'orderno', pushAdditionalValueForFilter)
            )}
            allowHeaderFiltering={false}
            defaultSortOrder="desc"
            hidingPriority={5}
            groupCellRender={cellInfo => (
                <>
                    {cellInfo.column.caption}
                    {': '}
                    {renderOrderNumber(cellInfo)}
                </>
            )}
        />,
        'text': <Column
            key="text"
            dataField="text"
            cssClass="cell-name"
            caption="Name"
            allowHeaderFiltering={false}
            allowSorting={false}
            allowGrouping={false}
        />,
        'status': <Column
            key="status"
            dataField="status"
            cssClass="cell-status"
            caption="Status"
            cellRender={rowData => renderReportStatus(rowData, combinedReports, isWebDelivery)}
            allowGrouping={false}
            hidingPriority={2}
            minWidth={120}
        />,
        'smItem.description': <Column
            key="smItem.description"
            dataField="smItem.description"
            cssClass="cell-report-type"
            caption="Report Type"
            cellRender={data => renderReportType(
                data,
                removeFromNew,
                shouldRemoveFromNew,
                dataGridRef.current.instance,
                isWebDelivery,
            )}
            minWidth={150}
        />,
        'pages': <Column
            key="pages"
            dataField="pages"
            cssClass="cell-pages"
            caption="Pages"
            allowHeaderFiltering={false}
            allowGrouping={false}
            hidingPriority={1}
            width={60}
        />,
        'donedate': <Column
            key="donedate"
            dataField="donedate"
            cssClass="cell-complete-date"
            caption="Date Complete"
            dataType="date"
            hidingPriority={4}
            format={dxShortDateFormat}
            calculateCellValue={rowData => getShortDate(rowData.donedate)}
            defaultSortOrder={defaultSorting.fieldName === 'donedate' ? defaultSorting.direction : undefined}
        />,
        'taxContinuationOrderActions': <Column
            key="actions"
            caption="Actions"
            cssClass="cell-actions"
            hidingPriority={5}
            cellRender={data => renderTaxActions(data, filters)}
            width={90}
        />,
        'judgmentContinuationOrderActions': <Column
            key="actions"
            caption="Actions"
            cssClass="cell-actions"
            hidingPriority={5}
            cellRender={data => renderJudgmentActions(data, selectOrder)}
            width={90}
        />,
    };

    return columnNames.map(name => columns[name]);
};

const getStore = (
    handleLoadingSuccess,
    handleLoadingError,
    handleLoadingStart,
    reportsType,
    tableColumns,
    setSort,
    dataGridRef,
) => new CustomStore({
    useDefaultSearch: true,
    onLoaded(result) {
        handleLoadingSuccess(result);
        Promise.resolve().then(() => dataGridRef.current?.instance.repaint());
    },
    onLoading() {
        handleLoadingStart();
    },
    load({
        sort,
        filter,
        take,
        skip,
    }) {
        setSort(sort);
        return fetchReports(
            reportsType,
            tableColumns,
            {
                sort,
                filter,
                take,
                skip,
            },
        )
            .then(data => (
                {
                    data: sortDataBySeveralFields(data.data, [sort && sort[0], { selector: 'text', desc: false }]),
                    totalCount: data.total,
                }
            ))
            .catch(error => {
                handleLoadingError(error);
                throw new Error(error);
            });
    },
    key: 'id',
});

const ReportsTable = ({
    data,
    onSelectionChanged = () => {},
    tableColumns,
    actionsColumn,
    handleLoadingStart,
    handleLoadingSuccess,
    handleLoadingError,
    removeFromNew,
    selectOrder,
    pushAdditionalValueForFilter,
    reportsType,
    filters = {},
    setSort = () => {},
    isSelectable = false,
    helpLabel,
    combinedReports = [],
    isExportAllowed,
    onExport,
    onSelectChosenItems,
    onChangePageSize = () => {},
    allowGrouping = true,
    allowSorting = true,
    shouldRemoveFromNew = false,
    defaultSorting = {},
    isWebDelivery,
    isSctUser,
    shouldReloadData,
}) => {
    const dataGridRef = useRef(null);
    const hasExternalStore = !!data;
    const [isGroupingEnabled, setIsGroupingEnabled] = useState(false);
    const [selectedReportsIds, setSelectedReportsIds] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    useEffect(() => {
        if (!hasExternalStore) {
            const dataGridFilters = getDataGridFilters(filters);
            dataGridRef.current.instance.filter(dataGridFilters);
            dataGridRef.current.instance.refresh(true);
        }
    }, [filters]);

    useEffect(() => {
        if (hasExternalStore) {
            dataGridRef.current.instance.pageIndex(0);
        }
    }, [data]);

    useEffect(() => {
        if (shouldReloadData) {
            dataGridRef.current.instance.refresh();
        }
    }, [shouldReloadData]);

    const customStore = useMemo(() => getStore(
        handleLoadingSuccess,
        handleLoadingError,
        handleLoadingStart,
        reportsType,
        tableColumns,
        setSort,
        dataGridRef,
    ), []);

    const columns = useMemo(
        () => getColumns(
            [...tableColumns, actionsColumn],
            removeFromNew,
            shouldRemoveFromNew,
            dataGridRef,
            combinedReports,
            pushAdditionalValueForFilter,
            selectOrder,
            defaultSorting,
            filters,
            isWebDelivery,
            isSctUser,
        ),
        [combinedReports, customStore, data, isWebDelivery, isSctUser, shouldRemoveFromNew],
    );

    const onToolbarPreparing = (e, isExportAllowed, onExport, onSelectChosenItems, selectedReportsIds) => {
        if (
            onExport
            && isExportAllowed
            && e.component.getDataSource().items().length
        ) {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'export',
                    hint: 'Export to CSV',
                    onClick: onExport,
                },
            });
        }

        if (onSelectChosenItems && isSelectable) {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: `Select (${selectedReportsIds.length})`,
                    hint: 'Select',
                    icon: 'selectall',
                    disabled: !selectedReportsIds.length,
                    onClick: () => onSelectChosenItems(e),
                },
            });
        }
    };

    const memoizedCellPreparedHandler = useCallback(
        e => onCellPreparedHandler(e, e.data && e.data.type, reportsType),
        [],
    );

    const memoizedToolbarPreparingHandler = useCallback(
        e => onToolbarPreparing(
            e, isExportAllowed, onExport, onSelectChosenItems, selectedReportsIds,
        ),
        [selectedReportsIds, isExportAllowed, filters, onExport],
    );

    const memoizedOptionChangedHandler = useCallback(
        e => {
            if (e.name === 'columns') {
                if ((e.fullName.endsWith('sortOrder') || (e.fullName.endsWith('filterValue')))) {
                    dataGridRef.current.instance.pageIndex(0);
                } else if (e.fullName.endsWith('groupIndex')) {
                    setIsGroupingEnabled(e.previousValue !== 0);
                }
            } else if (e.name === 'paging') {
                if (e.fullName.endsWith('pageSize')) {
                    dataGridRef.current.instance.pageIndex(0);
                    setTimeout(() => onChangePageSize(e.value), 10);
                }
            }
        },
        [dataGridRef],
    );
    const memoizedSelectionChangedHandler = useCallback(
        e => {
            onSelectionChangedHandler(e, getDisabledKeysFromReports, e => {
                onSelectionChanged(e);
                setSelectedRowKeys(
                    [...new Set(e.selectedRowsData
                        .filter(({ type }) => !nonSelectableReportTypes.includes(type))
                        .map(({ chain, id }) => chain || id)
                        .flat())],
                );
            });
            setSelectedReportsIds(getSelectedChainsLastReportsIds(e.component.getSelectedRowsData()));
        },
        [],
    );

    return (
        <>
            <Row>
                <Col md={12} xs={24}>
                    {
                        allowGrouping
                        && <InfoText message="Drag & drop column headers here to group by that field" />
                    }
                </Col>
                <Col md={12} xs={24}>
                    {
                        helpLabel
                        && <span className="help-block text-right">{helpLabel}</span>
                    }
                </Col>
            </Row>
            <DataGrid
                ref={dataGridRef}
                id="gridContainer"
                dataSource={hasExternalStore ? data : customStore}
                allowColumnReordering
                allowColumnResizing
                allowSorting={allowSorting}
                columnHidingEnabled
                columnAutoWidth
                wordWrapEnabled
                keyExpr="id"
                showBorders
                showRowLines
                showColumnLines
                width="100%"
                rowAlternationEnabled={!isGroupingEnabled}
                onSelectionChanged={memoizedSelectionChangedHandler}
                onToolbarPreparing={memoizedToolbarPreparingHandler}
                onOptionChanged={memoizedOptionChangedHandler}
                onCellPrepared={memoizedCellPreparedHandler}
                selectedRowKeys={selectedRowKeys}
            >
                <LoadPanel enabled={false} />
                <HeaderFilter visible={false} />
                <StateStoring enabled={false} type="localStorage" storageKey={`${reportsType}TableSettings`} />
                <RemoteOperations
                    sorting
                    paging
                    filtering
                />
                <GroupPanel visible={allowGrouping} emptyPanelText="Grouping area" />
                <Grouping
                    allowCollapsing={false}
                />
                {
                    !allowSorting
                    && <Sorting mode="none" />
                }
                <FilterRow
                    applyFilterText="Apply filter"
                    visible={false}
                />
                <Paging
                    defaultPageSize={25}
                />
                <Pager
                    showPageSizeSelector
                    allowedPageSizes={allowedPageSizes}
                    showInfo
                />
                {
                    isSelectable
                    && (
                        <Selection
                            mode="multiple"
                            selectAllMode="page"
                            showCheckBoxesMode={isSelectable ? 'always' : 'none'}
                        />
                    )
                }
                {columns}
            </DataGrid>
        </>
    );
};

export default ReportsTable;
